<template>
  <v-container>
    <page-title title="Association SEDUNI" />

    <v-row class="my-3">
      <v-spacer></v-spacer>
      <v-col>
        <v-row>
          <v-col class="text-center">
            <facebook-link>Rejoignez-nous</facebook-link>
          </v-col>
          <v-col class="text-center">
            <instagram-link>Suivez-nous</instagram-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <!-- NOTE Use the commented section below to promote an event in the home page. -->
    <v-divider />

    <v-row class="my-3 justify-center align-center">
      <v-col cols="12" md="4" class="text-right d-none d-md-inline">
        <v-icon size="100" color="primary" style="margin-right: 100px;">
          mdi-sword-cross
        </v-icon>
      </v-col>

      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card
            :to="{ path: 'training-course' }"
            :elevation="hover ? 16 : 2"
            dark
          >
            <v-img
              :src="require('@/assets/img/training_course_1.jpg')"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="300px"
            >
              <v-card-title>
                Journée découverte<br />
                Dimanche 18 mai 2025<br />
                Ayent (VS-CH)
              </v-card-title>
            </v-img>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>

      <v-col cols="12" md="4" class="d-none d-md-inline">
        <v-icon size="100" color="primary" style="margin-left: 100px;">
          mdi-sword-cross
        </v-icon>
      </v-col>
    </v-row>
   <!-- End of promoted section -->

    <v-divider />

    <v-row class="my-3 justify-center align-center">
      <v-col v-for="card in primaryCards" :key="card.title" cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card :to="card.to" :elevation="hover ? 16 : 2" dark>
            <v-img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="300px"
            >
              <v-card-title v-text="card.title"></v-card-title>
            </v-img>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="my-3 justify-center">
      <v-col v-for="card in secondaryCards" :key="card.title" cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card :to="card.to" :elevation="hover ? 12 : 2">
            <v-card-title v-text="card.title"></v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <div class="mt-5 d-flex justify-center">
        <a href="https://www.faitsdarmes.com/fr/" target="_blank"
           title="Boutique Faits D'Armes : Spécialistes des équipement pour Arts Martiaux Historiques (AMHE)">
          <img :src="require('@/assets/img/faitsdarmes.png')"
               alt="Ce club est partenaire officiel de la boutique Faits d'Armes">
        </a>
    </div>

  </v-container>
</template>

<script>
import FacebookLink from '@/components/FacebookLink.vue'
import InstagramLink from '@/components/InstagramLink.vue'

export default {
  components: { FacebookLink, InstagramLink },
  data() {
    return {
      primaryCards: this.$store.getters.primaryPages,
      secondaryCards: this.$store.getters.secondaryPages
    }
  }
}
</script>
